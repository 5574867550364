<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-center flex-column">
        <b-row class="topbar">
          <b-col cols="6" sm="12">
            <loader class="loader" ref="loader"></loader>
          </b-col>
        </b-row>
        <b-row class="rowitem color offset-top">
          <b-col cols="12" sm="5">
            <label>
              {{ T("Web.Generic.Color", "Color") }}
            </label>
            <div class="flex">
              <input
                type="text"
                class="form-control"
                :value="color"
                @change="updateColor"
              />
              <input
                type="color"
                class="form-control"
                :value="color"
                @change="updateColor"
              />
              <b-button
                variant="primary"
                class="mr-2"
                value="#407e3d"
                type="button"
                @click="updateColor"
              >
                {{ T("Web.Generic.Reset", "Reset") }}
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row class="rowitem offset-top">
          <b-col cols="12" sm="5">
            <label>
              {{ T("Web.Generic.Logo", "Logo") }}
            </label>
            <div class="flex">
              <b-form class="p-2" @submit.stop.prevent="handleSubmit">
                <div class="flex">
                  <b-form-file
                    :placeholder="T('Web.Generic.Choosefile', 'Choose a file')"
                    no-drop
                    :browse-text="T('Web.Generic.Choose', 'Choose')"
                    v-model="file"
                    :multiple="false"
                  />
                  <b-button
                    @click="resetLogo"
                    variant="outline-primary"
                    class="ml-2 mr-2"
                  >
                    {{ T("Web.Generic.Reset", "Reset") }}
                  </b-button>
                  <b-button variant="primary" class="mr-2" type="submit">
                    {{ T("Web.Generic.Upload", "Upload") }}
                  </b-button>
                </div>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import loader from "@/components/layout/loader.vue";
import ConcernService from "@/services/concern/concern-services";

import {
  BSpinner,
  BCard,
  BButton,
  BRow,
  BCol,
  BTabs,
  BTab,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
} from "bootstrap-vue";
export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BSpinner,
    BCard,
    BButton,
    BRow,
    BCol,
    BTabs,
    BTab,
    loader,
  },
  async created() {
    this.concernService = new ConcernService();
  },
  data() {
    return {
      file: null,
      concernService: null,
    };
  },
  computed: {
    color() {
      return this.$store.getters[`app/themeColor`]
        ? this.$store.getters[`app/themeColor`]
        : "#407e3d";
    },
  },
  methods: {
    ...mapActions({}),
    async updateColor(val) {
      this.$refs.loader.loading();
      await this.concernService.setThemeColor(val.target.value);
      this.$store.commit("app/UPDATE_THEME_COLOR", val.target.value);
      this.$refs.loader.loadCompelete();
    },
    async handleSubmit() {
      this.$refs.loader.loading();
      let result = await this.concernService.setThemeLogo(this.file);
      this.$store.commit("app/UPDATE_Custom_Logo", result.data.path);
      this.$refs.loader.loadCompelete();
    },
    async resetLogo() {
      this.$refs.loader.loading();
      await this.concernService.resetThemeLogo();
      this.$store.commit("app/UPDATE_Custom_Logo", "");
      this.$refs.loader.loadCompelete();
    },
  },
};
</script>

<style lang="scss" scoped>
.p-2 {
  padding: 0 !important;
  width: 100%;
}
.color {
  .flex {
    * {
      width: 30%;
    }
    button {
      width: 25%;
    }
  }
}
.flex {
  display: flex;
  justify-content: space-between;
  input,
  label,
  > div {
    width: 49%;
  }
  button {
    width: 25%;
  }
}
.topbar {
  position: relative;
  .loader {
    position: absolute;
    top: -15px;
    right: 0;
  }
}
.row {
  align-items: end;
}
.rowitem {
  &.offset-top {
    &:first-of-type {
      margin-top: 0;
    }
    margin-top: 10px;
  }
}
.offset-top {
  margin-top: 20px;
}
</style>